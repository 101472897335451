import { http, httpNoAuth } from "..";
import {
  createBidPayload,
  deleteProductPayload,
  getBidsFilter,
  getProductsFilter,
  markBidAsCompletedPayload,
  recordProductSalePayload,
  restoreProductPayload,
} from "./product-interface";

/**
 * Create product
 * @param data object
 * @returns http response
 */
export const createProduct = async (data: any) => {
  return await http.post("/products/create-product", data);
};

export const createProductSell = async (data: any) => {
  return await http.post("/products/create-product-sell", data);
};

/**
 * Edit product
 * @param data object
 * @returns http response
 */
export const editProduct = async (data: any, productId: string) => {
  return await http.patch(`/products/edit-product/${productId}`, data);
};

/**
 * Get product
 * @param data filter
 * @returns http response
 */
export const getProducts = async (filter?: getProductsFilter) => {
  return await httpNoAuth.get("/products", {
    params: {
      ...filter,
    },
  });
};

/**
 * Create bid
 * @param data object
 * @returns http response
 */
export const createBid = async (data: createBidPayload) => {
  return await http.post("/products/create-bid", data);
};

export const fetchBids = async (data: getBidsFilter) => {
  return await http.get("/products/get-bids", {
    params: {
      ...data,
    },
  });
};

export const markBidAsCompleted = async (data: markBidAsCompletedPayload) => {
  return await http.post("/products/mark-bid-as-completed", data);
};

/**
 * Delete product
 * @param data object
 * @returns http response
 */
export const deleteProduct = async (data: deleteProductPayload) => {
  return await http.delete("/products/delete-product", {
    params: {
      ...data,
    },
  });
};

/**
 * Restore product
 * @param data object
 * @returns http response
 */
export const restoreProduct = async (data: restoreProductPayload) => {
  return await http.post("/products/restore-product", data);
};


export const recordProductSale = async (data: recordProductSalePayload, productId: string) => {
  return await http.patch(`/products/record-sale/${productId}`, data);
};