import { http } from "..";
import {
  getQuestionsFilter,
  markQuestionAsClosedPayload,
  sendNotificationPayload,
} from "./base-interface";

/**
 * Get metrics
 * @returns http response
 */

export const fetchQuestions = async (data: getQuestionsFilter) => {
  return await http.get("/questions", {
    params: {
      ...data,
    },
  });
};

export const markQuestionAsClosed = async (
  data: markQuestionAsClosedPayload
) => {
  return await http.post("/questions/mark-question-as-closed", data);
};

export const getAppMetrics = async () => {
  return await http.get("/app-metrics");
};

export const sendNotification = async (data: sendNotificationPayload) => {
  return await http.post("/products/send-notification", data);
};
