import React, { useState } from "react";
import { sendNotificationProps } from "./products-interface";
import { Alert, Button, Form, Input, message, Modal } from "antd";
import { sendNotification } from "../../../../api/base/base";
import { openNotificationWithIcon } from "../../../../utils/helper";

const Sendnotification = ({
  workingProduct,
  closeModal,
  open,
}: sendNotificationProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    message: "",
  });

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res = await sendNotification({
        message: values?.message,
        productId: workingProduct?._id as string,
        title: values?.title,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", `Send Notification`, message);

      closeModal();
    } catch (error: any) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        message.error(errorMessage);
      }
    }
  };

  return (
    <div>
      <Modal
        title={`Send Notification for ${workingProduct?.name}`}
        open={open}
        onOk={
          loading
            ? undefined
            : () => {
                closeModal();
              }
        }
        onCancel={
          loading
            ? undefined
            : () => {
                closeModal();
              }
        }
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
      >
        <div>
          <Alert
            // message="Product Details"
            description={
              <>
                <div>
                  <p className="font-bold">Product Details</p>
                  <p>
                    <b>Name:</b> {workingProduct?.name}
                  </p>
                  <p>
                    <b>ID:</b> {workingProduct?.selfId}
                  </p>
                  <p>
                    <b>Product Link:</b>{" "}
                    <a
                      href={`https://desibazaarapp.com/product/${workingProduct?.selfId}`}
                    >
                      {`https://desibazaarapp.com/product/${workingProduct?.selfId}`}
                    </a>
                  </p>
                </div>

                <div className="mt-8">
                  <p className="font-bold">Seller Details</p>
                  <p>
                    <b>Name:</b> {workingProduct?.createdBy?.lastName}{" "}
                    {workingProduct?.createdBy?.firstName}
                  </p>
                  <p>
                    <b>Email:</b> {workingProduct?.createdBy?.email}
                  </p>
                  <p>
                    <b>Phone:</b> {workingProduct?.createdBy?.phoneNumber}
                  </p>
                  <p>
                    <b>Instagram Handle:</b>{" "}
                    {workingProduct?.createdBy?.instagramHandle}
                  </p>
                </div>
              </>
            }
            type="info"
          />
        </div>
        <div className="my-4">
          <p>
            Fill in the correct details below to send a notification for this
            product
          </p>
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            validateStatus={errors?.title.length ? "error" : undefined}
            help={errors?.title.length ? errors?.title : undefined}
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
            label="Notification Title"
          >
            <Input />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.message.length ? "error" : undefined}
            help={errors?.message.length ? errors?.message : undefined}
            name="message"
            rules={[
              {
                required: true,
              },
            ]}
            label="Notification Message"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className="bg-appColorBlue2"
              disabled={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Sendnotification;
